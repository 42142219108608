
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/visitor";
import moment from "moment";

@Options({})
export default class extends Vue {
  private progressList: any[] = [];
  title = "访客审批";
  isEmpty = true;

  mounted() {
    api.getUserNeedApproveInfo({}).then((res: any) => {
      if (res.code === 0) {
        this.progressList = res.data;
        this.isEmpty = this.progressList.length > 0 ? false : true;
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }

  approveStatusFilter(val: any) {
    if (val === 0) {
      return "未审批";
    } else if (val === 1) {
      return "未开始";
    } else if (val === 2) {
      return "生效中";
    } else if (val === 3) {
      return "已结束";
    }
  }

  approveSexFilter(val: any) {
    if (val === 1) {
      return "未知";
    } else if (val === 2) {
      return "男";
    } else if (val === 3) {
      return "女";
    }
  }

  endRealFilter(row: any) {
    if (row.status === 2) {
      if (row.endReal && row.endPlan) {
        const s1 = moment(row.endPlan);
        const s2 = moment(row.endReal);
        const diff = s2.diff(s1, "days");
        if (diff > 0) {
          return "red";
        } else {
          return "green";
        }
      }
    } else {
      if (row.endPlan) {
        const now = moment(new Date());
        const s1 = moment(row.endPlan);
        const diff = now.diff(s1, "days");
        if (diff <= 0 && diff >= -10) {
          return "orange";
        } else if (diff > 0) {
          return "red";
        }
      }
    }
    return "";
  }

  protected progressDetail(val: any) {
    let data = JSON.stringify(val);
    this.$router.push({
      path: "/app/visitorApproveInfo",
      query: { val: data },
    });
  }
}
