import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-174c2c4a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progressplan-container" }
const _hoisted_2 = { class: "progress-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "left-container" }
const _hoisted_5 = { class: "right-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_empty = _resolveComponent("van-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    (_ctx.isEmpty)
      ? (_openBlock(), _createBlock(_component_van_empty, {
          key: 0,
          class: "custom-image",
          image: require('../../../assets/empty-bg.png'),
          description: "没有更多了"
        }, null, 8, ["image"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progressList, (li, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "progress",
          key: index,
          onClick: ($event: any) => (_ctx.progressDetail(li))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, "姓名:" + _toDisplayString(li.name) + " 性别:" + _toDisplayString(_ctx.approveSexFilter(li.sex)), 1),
            _createElementVNode("div", null, "预计来访：" + _toDisplayString(li.startPlan), 1),
            _createElementVNode("div", null, "预计结束：" + _toDisplayString(li.endPlan), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createElementVNode("span", {
                style: _normalizeStyle({
                color:
                  li.status === 0
                    ? '#FEA235'
                    : li.status === 1
                    ? '#C820E0'
                    : li.status === 2
                    ? '#8893A3'
                    : '#E02020',
              })
              }, _toDisplayString(_ctx.approveStatusFilter(li.status)), 5)
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                style: _normalizeStyle({ color: _ctx.endRealFilter(li) })
              }, null, 4)
            ])
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}